<template>
  <div class="d-flex align-items-center w-100 mt-4">
    <div v-for="(breadcrumb, index) of breadcrumbs" :key="index" class="one-breadcrumb">
      <router-link class="text breadcrumb-text" :class="{ disabled: breadcrumb.isActive }" :to="breadcrumb.href">{{
        breadcrumb.label
      }}</router-link>
      <i v-if="index < breadcrumbs.length - 1" class="ti-angle-right right-icon mx-1" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Breadcrumb } from '../types';

const props = defineProps({
  breadcrumbs: {
    type: Array,
    default: [],
  },
});

const breadcrumbs = computed<Breadcrumb[]>(() => toRef(props.breadcrumbs).value as Breadcrumb[]);
</script>

<style lang="sass" scoped>
.text
  &.disabled
    cursor: default !important
    pointer-events: none !important
    color: #214163
.right-icon
  font-size: 10px
.title
  font-size: 16px
  font-weight: 700
.action-btn
  text-transform: unset
  font-size: 13px
  user-select: none !important
</style>
